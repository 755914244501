var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mt-3",attrs:{"fluid":"","grid-list-xl":""}},[_c('v-layout',{staticClass:"pb-11 pt-0",attrs:{"justify-center":"","align-center":""}},[(_vm.responsive)?_c('v-img',{staticClass:"mr-2",attrs:{"src":"https://utaw.blob.core.windows.net/images/ykclogo.png","height":"131","width":"130","contain":""}}):_vm._e(),(!_vm.responsive)?_c('v-img',{attrs:{"src":"https://utaw.blob.core.windows.net/images/ykclogo.png","height":"181","width":"180","contain":""}}):_vm._e()],1),_c('v-layout',{attrs:{"align-start":"","wrap":""}},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"pt-0",attrs:{"xs12":"","md6":""}},[_c('v-card',{staticClass:"pa-5 mx-3 mb-3 yk-card"},[(!_vm.account && _vm.loading)?_c('v-progress-linear',{staticClass:"mx-2 mt-2",attrs:{"indeterminate":"","color":"#4289b0"}}):_vm._e(),(_vm.account && !_vm.loading)?_c('div',[_c('v-layout',{staticClass:"pb-0 pt-3 pr-3",attrs:{"justify-end":""}},[_c('div',{staticClass:"title font-weight-bold"},[_vm._v(" "+_vm._s(_vm.account.nameH)+" ")])]),_c('div',{staticClass:"title my-3 pt-0",staticStyle:{"color":"#4289b0"}},[_vm._v(" INVOICES ")]),_vm._l((_vm.transactions.filter(
                                (t) => t.txnType == 'D'
                            )),function(transaction,index){return _c('div',[_c('v-layout',{staticClass:"px-3 py-2",attrs:{"row":"","justify-space-between":""}},[_c('div',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(transaction.description)+" ")]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm._f("currency")(transaction.amount))+" ")])]),(
                                    index + 1 <
                                    _vm.transactions.filter(
                                        (t) => t.txnType == 'D'
                                    ).length
                                )?_c('v-divider',{staticClass:"py-2"}):_vm._e()],1)}),(_vm.hasPayments())?_c('div',[_c('div',{staticClass:"title my-3 pt-2",staticStyle:{"color":"#4289b0"}},[_vm._v(" PAYMENTS & CREDITS ")]),_vm._l((_vm.transactions.filter(
                                    (t) => t.txnType == 'C'
                                )),function(transaction,index){return _c('div',[_c('v-layout',{staticClass:"px-3 py-2",attrs:{"row":"","justify-space-between":""}},[_c('div',[_c('div',{staticClass:"font-weight-regular"},[(
                                                    _vm.isScheduled(
                                                        transaction.date
                                                    )
                                                )?_c('span',{staticClass:"mr-2",staticStyle:{"color":"rgb(253, 180, 0)"}},[_vm._v("SCHEDUELD")]):_vm._e(),_vm._v(_vm._s(_vm._f("date")(transaction.date))+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.getPaymentType(transaction))+" ")])]),_c('div',{staticClass:"font-weight-medium"},[_vm._v(" -"+_vm._s(_vm._f("currency")(transaction.amount))+" ")])]),(
                                        index + 1 <
                                        _vm.transactions.filter(
                                            (t) => t.txnType == 'C'
                                        ).length
                                    )?_c('v-divider',{staticClass:"py-2"}):_vm._e()],1)})],2):_vm._e(),_c('v-layout',{staticClass:"pa-3 mt-3 title",staticStyle:{"color":"#4289b0"},attrs:{"row":"","justify-space-between":""}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Balance")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.account.balance))+" ")])])],2):_vm._e()],1),_c('v-card',{staticClass:"pa-5 mx-3 yk-card"},[(!_vm.account && _vm.loading)?_c('v-progress-linear',{staticClass:"mx-2 mt-2",attrs:{"indeterminate":"","color":"#4289b0"}}):_vm._e(),(_vm.account && !_vm.loading)?_c('div',[_c('div',{staticClass:"mb-4 mt-0"},[_c('div',{staticClass:"title mb-3 pt-0",staticStyle:{"color":"#4289b0"}},[_vm._v(" REGISTRATION ")])]),_vm._l((_vm.students),function(student){return _c('div',[_c('v-layout',{staticClass:"pa-3 mb-2",attrs:{"row":""}},[_c('div',{staticClass:"font-weight-regular title"},[_vm._v(" "+_vm._s(student.firstNameH)+" ")]),_c('div',{staticClass:"mx-2"}),_c('div',{staticClass:"font-weight-regular title",class:{
                                        'darken-1 green--text text--darken-1':
                                            student.registrationStatus ==
                                            'REGISTERED',
                                        'darken-1 red--text text--darken-1':
                                            student.registrationStatus ==
                                            'NOT REGISTERED',
                                    }},[_vm._v(" "+_vm._s(student.registrationStatus)+" ")])]),_vm._l((student.requirments.filter(
                                    (r) => r.showRequirment
                                )),function(requirment,index){return _c('div',[_c('v-layout',{staticClass:"pa-3",attrs:{"row":"","justify-space-between":""}},[_c('div',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.getRequirmentDescription( requirment.description ) + " - " + requirment.status)+" ")]),_c('v-spacer'),(
                                            _vm.allowUpload(
                                                requirment.status
                                            ) &&
                                            requirment.description !=
                                                'Insurance / Medicaid Card'
                                        )?_c('v-btn',{staticClass:"button mr-2",attrs:{"small":"","loading":_vm.processing},on:{"click":function($event){return _vm.download(requirment, student)}}},[_vm._v(" Download ")]):_vm._e(),(
                                            _vm.allowUpload(requirment.status)
                                        )?_c('div',[_c('v-btn',{staticClass:"button mr-2",attrs:{"small":"","loading":_vm.uploading || _vm.processing},on:{"click":function($event){return _vm.pickFile(requirment.id)}}},[_vm._v(" Upload ")]),_c('input',{ref:"files",refInFor:true,staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*,.pdf"},on:{"change":function($event){return _vm.onFilePicked(
                                                    $event,
                                                    requirment,
                                                    student
                                                )}}})],1):_vm._e()],1),(
                                        index + 1 <
                                        student.requirments.filter(
                                            (r) => r.showRequirment
                                        ).length
                                    )?_c('v-divider',{staticClass:"py-2"}):_vm._e()],1)})],2)})],2):_vm._e()],1)],1),_c('v-flex',{attrs:{"xs12":"","md6":""}},[_c('div',{staticClass:"mx-1"},[_c('payment',{ref:"payment",attrs:{"responsive":_vm.responsive,"account":_vm.account,"manualPayment":_vm.manualPayment}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }