<template>
    <v-container fluid grid-list-xl class="mt-3">
        <v-layout justify-center align-center class="pb-11 pt-0">
            <v-img
                v-if="responsive"
                class="mr-2"
                src="https://utaw.blob.core.windows.net/images/ykclogo.png"
                height="131"
                width="130"
                contain
            />
            <v-img
                v-if="!responsive"
                class=""
                src="https://utaw.blob.core.windows.net/images/ykclogo.png"
                height="181"
                width="180"
                contain
            />
        </v-layout>
        <v-layout align-start wrap>
            <v-layout row>
                <v-flex xs12 md6 class="pt-0">
                    <v-card class="pa-5 mx-3 mb-3 yk-card">
                        <v-progress-linear
                            class="mx-2 mt-2"
                            indeterminate
                            color="#4289b0"
                            v-if="!account && loading"
                        ></v-progress-linear>
                        <div v-if="account && !loading">
                            <!-- <div class="mb-4 mt-4">
                                <div class="login-title text-center">
                                    BALANCE
                                </div>
                            </div> -->
                            <v-layout class="pb-0 pt-3 pr-3" justify-end>
                                <div class="title font-weight-bold">
                                    {{ account.nameH }}
                                </div>
                            </v-layout>
                            <div class="title my-3 pt-0" style="color: #4289b0">
                                INVOICES
                            </div>
                            <div
                                v-for="(
                                    transaction, index
                                ) in transactions.filter(
                                    (t) => t.txnType == 'D'
                                )"
                            >
                                <v-layout
                                    class="px-3 py-2"
                                    row
                                    justify-space-between
                                >
                                    <div class="font-weight-regular">
                                        {{ transaction.description }}
                                    </div>
                                    <div class="font-weight-medium">
                                        {{ transaction.amount | currency }}
                                    </div>
                                </v-layout>
                                <v-divider
                                    class="py-2"
                                    v-if="
                                        index + 1 <
                                        transactions.filter(
                                            (t) => t.txnType == 'D'
                                        ).length
                                    "
                                ></v-divider>
                            </div>

                            <div v-if="hasPayments()">
                                <div
                                    class="title my-3 pt-2"
                                    style="color: #4289b0"
                                >
                                    PAYMENTS & CREDITS
                                </div>
                                <div
                                    v-for="(
                                        transaction, index
                                    ) in transactions.filter(
                                        (t) => t.txnType == 'C'
                                    )"
                                >
                                    <v-layout
                                        class="px-3 py-2"
                                        row
                                        justify-space-between
                                    >
                                        <div>
                                            <div class="font-weight-regular">
                                                <span
                                                    v-if="
                                                        isScheduled(
                                                            transaction.date
                                                        )
                                                    "
                                                    style="
                                                        color: rgb(253, 180, 0);
                                                    "
                                                    class="mr-2"
                                                    >SCHEDUELD</span
                                                >{{ transaction.date | date }}
                                            </div>
                                            <div class="caption">
                                                {{
                                                    getPaymentType(transaction)
                                                }}
                                            </div>
                                        </div>
                                        <div class="font-weight-medium">
                                            -{{ transaction.amount | currency }}
                                        </div>
                                    </v-layout>
                                    <v-divider
                                        class="py-2"
                                        v-if="
                                            index + 1 <
                                            transactions.filter(
                                                (t) => t.txnType == 'C'
                                            ).length
                                        "
                                    ></v-divider>
                                </div>
                            </div>

                            <v-layout
                                class="pa-3 mt-3 title"
                                style="color: #4289b0"
                                row
                                justify-space-between
                            >
                                <div class="font-weight-bold">Balance</div>
                                <div class="font-weight-bold">
                                    {{ account.balance | currency }}
                                </div>
                            </v-layout>
                        </div>
                    </v-card>
                    <v-card class="pa-5 mx-3 yk-card">
                        <v-progress-linear
                            class="mx-2 mt-2"
                            indeterminate
                            color="#4289b0"
                            v-if="!account && loading"
                        ></v-progress-linear>
                        <div v-if="account && !loading">
                            <div class="mb-4 mt-0">
                                <div
                                    class="title mb-3 pt-0"
                                    style="color: #4289b0"
                                >
                                    REGISTRATION
                                </div>
                            </div>
                            <div v-for="student in students">
                                <v-layout class="pa-3 mb-2" row>
                                    <div class="font-weight-regular title">
                                        {{ student.firstNameH }}
                                    </div>
                                    <div class="mx-2"></div>
                                    <div
                                        class="font-weight-regular title"
                                        v-bind:class="{
                                            'darken-1 green--text text--darken-1':
                                                student.registrationStatus ==
                                                'REGISTERED',
                                            'darken-1 red--text text--darken-1':
                                                student.registrationStatus ==
                                                'NOT REGISTERED',
                                        }"
                                    >
                                        {{ student.registrationStatus }}
                                    </div>
                                </v-layout>
                                <div
                                    v-for="(
                                        requirment, index
                                    ) in student.requirments.filter(
                                        (r) => r.showRequirment
                                    )"
                                >
                                    <v-layout
                                        class="pa-3"
                                        row
                                        justify-space-between
                                    >
                                        <div class="font-weight-regular">
                                            {{
                                                getRequirmentDescription(
                                                    requirment.description
                                                ) +
                                                " - " +
                                                requirment.status
                                            }}
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            v-if="
                                                allowUpload(
                                                    requirment.status
                                                ) &&
                                                requirment.description !=
                                                    'Insurance / Medicaid Card'
                                            "
                                            small
                                            class="button mr-2"
                                            :loading="processing"
                                            @click="
                                                download(requirment, student)
                                            "
                                        >
                                            Download
                                        </v-btn>
                                        <div
                                            v-if="
                                                allowUpload(requirment.status)
                                            "
                                        >
                                            <v-btn
                                                small
                                                class="button mr-2"
                                                :loading="
                                                    uploading || processing
                                                "
                                                @click="pickFile(requirment.id)"
                                            >
                                                Upload
                                            </v-btn>
                                            <input
                                                type="file"
                                                style="display: none"
                                                ref="files"
                                                accept="image/*,.pdf"
                                                @change="
                                                    onFilePicked(
                                                        $event,
                                                        requirment,
                                                        student
                                                    )
                                                "
                                            />
                                        </div>
                                        <!-- <div class="font-weight-medium">
                                            {{ requirment.status }}
                                        </div> -->
                                    </v-layout>
                                    <v-divider
                                        class="py-2"
                                        v-if="
                                            index + 1 <
                                            student.requirments.filter(
                                                (r) => r.showRequirment
                                            ).length
                                        "
                                    ></v-divider>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-flex>
                <v-flex xs12 md6>
                    <div class="mx-1">
                        <payment
                            :responsive="responsive"
                            ref="payment"
                            :account="account"
                            :manualPayment="manualPayment"
                        ></payment>
                    </div>
                </v-flex>
            </v-layout>
        </v-layout>
    </v-container>
</template>
<script>
//import moment from "moment";
import payment from "./Payment.vue";
import LogRocket from "logrocket";
import { formatPhoneNumber } from "../utils/helpers.js";

export default {
    components: {
        payment,
    },
    data() {
        return {
            responsive: false,
            showPaymentView: false,
            processing: false,
            manualPayment: true,

            recoverPinDialog: false,
            recoverPinPhone: "",
            recoveringPin: false,
            recoverPinError: "",
            recoverPinSuccess: false,

            pin: "",
            phoneNumber: "",

            manual: {
                name: "",
                phone: "",
                address: "",
                email: "",
                selectedCampaign: "",
                campaignReason: "",
            },

            account: null,
            transactions: [],
            requirments: [],
            students: [],
            documents: [],

            loading: false,
            processing: false,
            uploading: false,
            files: [],
            notFoundError: "",
            showLoadAccountMessage: false,

            required: [(v) => !!v || "Required."],

            emailRules: [
                (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return !value || pattern.test(value) || "Invalid e-mail.";
                },
            ],
        };
    },
    watch: {},
    async created() {
        this.setupBackground();

        await this.loadAccount();

        if (this.$store.state.app.account) {
            this.account = this.$store.state.app.account;
            this.transactions = this.$store.state.app.transactions;

            this.showPaymentView = true;
        }
    },
    computed: {},
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    methods: {
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
        setupBackground() {
            document
                .getElementById("main-background")
                .classList.remove("login-background");

            document
                .getElementById("main-background")
                .classList.add("main-background");

            document.getElementById("footer").classList.remove("login-footer");
            document.getElementById("footer").classList.add("main-footer");
        },
        hasPayments() {
            return this.transactions.some((t) => t.txnType == "C");
        },
        goBack(event) {
            if (this.showPaymentView) {
                this.showPaymentView = false;

                event.preventDefault();

                return;
            }
        },
        async loadAccount() {
            try {
                this.loading = true;

                const data = await this.$http.get("getAccount");

                this.account = data.account;
                this.transactions = data.transactions.map((transaction) => {
                    const student = data.students.find(
                        (s) => s.dayCampId == transaction.dayCampId
                    );

                    transaction.description =
                        transaction.description +
                        (student ? " - " + student.firstNameH : "");

                    return transaction;
                });
                this.documents = data.documents;
                this.students = data.students;

                this.setupRequirments(data);

                this.loading = false;

                LogRocket.identify(this.account.accountId, {
                    name: this.account.name,
                    email: this.account.emailAddress,
                });

                this.$store.commit("app/setAccount", {
                    account: this.account,
                    transactions: this.transactions,
                });

                // LogRocket.identify(this.getName(), {
                //     name: "Mr." + this.getName(),
                // });

                return;
            } catch (e) {
                //this.account = null;
                //this.transactions = [];

                this.loading = false;
            }
        },
        setupRequirments(data) {
            let number = 0;

            data.students.forEach((student) => {
                student.requirments = [
                    {
                        id: this.allowUpload(data.account.lunchDocumentStatus)
                            ? number++
                            : null,
                        description: "Lunch Document",
                        status: data.account.lunchDocumentStatus,
                        showRequirment:
                            data.account.lunchDocumentStatus != "NOT REQUIRED",
                    },
                    {
                        id: this.allowUpload(
                            this.getRequirmentStatus("Health Form", student)
                        )
                            ? number++
                            : null,
                        description: "Health Form",
                        status: this.getRequirmentStatus(
                            "Health Form",
                            student
                        ),
                        showRequirment: true,
                    },
                    {
                        id: this.allowUpload(
                            this.getRequirmentStatus(
                                "Insurance / Medicaid Card",
                                student
                            )
                        )
                            ? number++
                            : null,
                        description: "Insurance / Medicaid Card",
                        status: this.getRequirmentStatus(
                            "Insurance / Medicaid Card",
                            student
                        ),
                        showRequirment: true,
                    },
                    {
                        id: this.allowUpload(
                            this.getRequirmentStatus("Hiscyvis Letter", student)
                        )
                            ? number++
                            : null,
                        description: "Hiscyvis Letter",
                        status: this.getRequirmentStatus(
                            "Hiscyvis Letter",
                            student
                        ),
                        showRequirment: true,
                    },
                ];
            });
        },
        allowUpload(status) {
            return ["MISSING", "REJECTED"].includes(status);
        },
        getRequirmentStatus(requirment, student) {
            if (!this.documents.length) return "MISSING";

            if (requirment == "Lunch Document") {
                const docs = this.documents.filter(
                    (d) =>
                        d.accountId == student.accountId.trim() &&
                        d.category.toUpperCase() == "LUNCH"
                );

                const doc = docs.find((d) =>
                    ["APPROVED", "UPLOADED"].includes(d.status)
                )
                    ? docs.find((d) =>
                          ["APPROVED", "UPLOADED"].includes(d.status)
                      )
                    : docs[0];

                return doc ? doc.status : "MISSING";
            } else if (requirment == "Insurance / Medicaid Card") {
                const docs = this.documents.filter(
                    (d) =>
                        d.studentId == student.studentId.trim() &&
                        d.category.toUpperCase() == "INSURANCE CARD"
                );

                const doc = docs.find((d) =>
                    ["APPROVED", "UPLOADED"].includes(d.status)
                )
                    ? docs.find((d) =>
                          ["APPROVED", "UPLOADED"].includes(d.status)
                      )
                    : docs[0];

                return doc ? doc.status : "MISSING";
            } else if (requirment == "Hiscyvis Letter") {
                const docs = this.documents.filter(
                    (d) =>
                        d.studentId == student.studentId.trim() &&
                        d.category.toUpperCase() == "HISCYVIS"
                );

                const doc = docs.find((d) =>
                    ["APPROVED", "UPLOADED"].includes(d.status)
                )
                    ? docs.find((d) =>
                          ["APPROVED", "UPLOADED"].includes(d.status)
                      )
                    : docs[0];

                return doc ? doc.status : "MISSING";
            } else {
                const docs = this.documents.filter(
                    (d) =>
                        d.studentId == student.studentId.trim() &&
                        d.category.toUpperCase() == "HEALTH FORM"
                );

                const doc = docs.find((d) =>
                    ["APPROVED", "UPLOADED"].includes(d.status)
                )
                    ? docs.find((d) =>
                          ["APPROVED", "UPLOADED"].includes(d.status)
                      )
                    : docs[0];

                return doc ? doc.status : "MISSING";
            }
        },
        getRequirmentDescription(description) {
            if (description == "Hiscyvis Letter") return "כתב התחייבות";

            return description;
        },
        download(requirment, student) {
            let url = "";

            switch (requirment.description) {
                case "Health Form":
                    url =
                        "https://uta-admin-portal.azurewebsites.net/api/yk/downloadForm?accountID=" +
                        this.account.accountId.trim() +
                        "&studentID=" +
                        student.studentId.trim();
                    break;
                case "Lunch Document":
                    url =
                        "https://uta-admin-portal.azurewebsites.net/api/yk/downloadForm?accountID=" +
                        this.account.accountId.trim() +
                        "&studentID=&docType=YK_LUNCH";
                    break;
                case "Hiscyvis Letter":
                    url =
                        "https://utaw.blob.core.windows.net/publications/ykletter.pdf";
                    break;
            }

            window.open(url);
        },
        isScheduled(date) {
            return new Date() < new Date(date);
        },
        async saveVisit() {
            // try {
            //     await this.$http.post("api/account/saveVisit", {
            //         name: this.manual.name,
            //         phone: this.formatPhone(this.manual.phone),
            //     });
            // } catch (e) {
            //     return;
            // }
        },
        removeMainBackground() {
            document
                .getElementById("main-background")
                .classList.remove("main-background");
        },
        formatPhone(phone) {
            const value = phone.substr(phone.length - 10);

            return formatPhoneNumber(value);
        },
        getPaymentType(transaction) {
            if (transaction.txnType == "D") return;

            //if (transaction.paymentTypeId == 6) return "Lunch Program Discount";

            if ([1, 5, 7, 8, 9, 10, 6].includes(transaction.paymentTypeId))
                return transaction.description;

            return transaction.description.substr(
                0,
                transaction.description.indexOf(" ")
            );
        },
        pickFile(id) {
            this.$refs.files[id].click();
        },
        async onFilePicked(e, requirment, student) {
            this.uploading = true;

            const files = e.target.files;

            if (!files) return;

            this.files = files;

            this.uploading = false;

            this.saveDocument(requirment, student);
        },
        async saveDocument(requirment, student) {
            this.processing = true;

            try {
                var formData = new FormData();

                formData.append("image", this.files[0]);

                const docType =
                    requirment.description == "Lunch Document"
                        ? "LUNCH"
                        : requirment.description == "Health Form"
                        ? "HEALTH FORM"
                        : requirment.description == "Hiscyvis Letter"
                        ? "HISCYVIS"
                        : "INSURANCE CARD";

                await this.$http.post(
                    "UploadFile?accountID=" +
                        this.account.accountId +
                        "&documentType=" +
                        docType +
                        "&studentId=" +
                        student.studentId,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                this.processing = false;
                this.files = [];
                // this.documentType = [];

                this.loadAccount();
            } catch (e) {
                this.processing = false;
            }
        },
        openRecoverPinDialog() {
            this.recoverPinSuccess = false;
            this.recoverPinDialog = true;
        },
        async recoverPin() {
            if (!this.$refs.recoverPinPhone.validate(true)) return;

            this.recoveringPin = true;

            try {
                await this.$http.post(
                    "api/account/recoverPin?phone=" +
                        this.recoverPinPhone
                            .replace(/-/g, "")
                            .substr(this.recoverPinPhone.length - 10)
                );

                this.recoveringPin = false;
                this.recoverPinSuccess = true;
            } catch (e) {
                this.recoverPinSuccess = false;
                this.recoverPinError = e;

                this.recoveringPin = false;
            }
        },
    },
};
</script>
<style>
.rtl {
    text-align: right !important;
    direction: rtl;
}
.scrollable {
    overflow-y: auto;
    height: 300px;
}
.login-card {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 5px solid #389466 !important;
    border-radius: 22px !important;
}
.login-button {
    text-align: left !important;
    font: Medium 14px/20px Roboto;
    letter-spacing: 0 !important;
    color: #ffffff !important;
    background: #389466 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 2px 2px #0000003d;
    border-radius: 2px;
    opacity: 1;
}
.no-pin-card {
    border: 1px solid #00000030 !important;
    border-radius: 10px !important;
    opacity: 1 !important;
    padding: 20px;
    width: 80%;
}
.call-me-now-button {
    text-align: center !important;
    font: Medium 10px/20px Roboto;
    letter-spacing: 0;
    color: #389466 !important;
    background: #99999933 0% 0% no-repeat padding-box !important;
    border-radius: 2px !important;
}
.green-text {
    color: #389466;
}
.lotto-logo {
    position: absolute;
    top: -92px;
    right: -60px;
    height: 146px;
    width: 146px;
}
.lotto-logo-mobile {
    position: absolute;
    top: -112px;
    right: -60px;
    height: 146px;
    width: 146px;
    margin-left: -73px;
    left: 50%;
}
</style>
